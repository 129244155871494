<template>
    <section>
        <navigator linkColor="Blanco" />

        <section class="hero">


            <div>
                <div>
                    <h1 data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="700">
                        <strong>Installation and commissioning</strong><br>in Chicago IL.
                    </h1>
                    <h3 data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="700"
                        data-aos-delay="500">by DavisInd Group</h3>
                </div>
                <a href="#contact">
                    <button>
                        <p>Contact us</p> <img src="../../assets/arrow3.svg" alt="Flecha de continuar">
                    </button>
                </a>
            </div>
        </section>
        <section class="description">
            <picture data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="700">
                <source srcset="../../assets/aboutRobotArm.webp" type="image/webp">
                <img src="../../assets/aboutRobotArm.png" alt="imagen de brazo de robot">
            </picture>
            <p>At <strong>DavisInd Group</strong> , our expertise in on-site robotic commissioning ensures the flawless
                installation and
                setup of automated systems, specifically tailored to the demanding standards of the automotive industry.
                <br> <br>

                Our comprehensive <strong> industrial robot programming</strong> and commissioning services are designed
                to meet the
                intricate design requirements of our clients across Chicago.
            </p>
        </section>
        <section class="services">
            <h3>Tailored Installation and Commissioning Services</h3>
            <p><strong>Installation and Commissioning:</strong> Emphasizing the safety and integrity of your equipment
                in Chicago,
                we specialize in
                machine installation and commissioning, verifying every piece before and after the commissioning phase.
                <br> <br>
                <strong>Our services cover in Chicago:</strong>
            </p>
            <div class="contService">
                <div data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="500" class="itemService">
                    <img src="../../assets/ServiceIndustrial.svg" alt="servicio industrial">
                    <p>Industrial Robotics <br> Programming</p>
                    <span>Precision programming for optimal system performance.</span>
                </div>
                <div data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="50"
                    class="itemService">
                    <img src="../../assets/ServiceAdvanced.svg" alt="servicio industrial">
                    <p>Advanced Robotic <br>Applications</p>
                    <span>Implementing cutting-edge solutions in the automative industry.</span>
                </div>
                <div data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="100"
                    class="itemService">
                    <img src="../../assets/ServiceRobotics.svg" alt="servicio industrial">
                    <p>Robotics Support for the <br> Automotive Industry </p>
                    <span>Expertise in cell relocations and system integrations.</span>
                </div>
                <div data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="150"
                    class="itemService">
                    <img src="../../assets/ServiceRobotics.svg" alt="servicio industrial">
                    <p>Automation System <br> Installation </p>
                    <span>Ensuring seamless operation from electrical wiring to control systems.</span>
                </div>
            </div>
        </section>
        <section class="information">
            <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="50"
                class="informationItem">
                <picture>
                    <source srcset="../../assets/infoOur.webp" type="image/webp">
                    <img src="../../assets/infoOur.png" alt="foto de investigadores de robotica">
                </picture>
                <div>
                    <h4><strong>Our</strong> <br>
                        Specialized Team</h4>
                    <p>With a solid foundation of industrial automation services in Chicago, DavisInd Group boasts a
                        diverse pool
                        of specialists ready to address any challenge:
                    <ul>
                        <li>Mechatronics Engineers</li>
                        <li>Mechanical Engineers</li>
                        <li>Electrical Engineers</li>
                    </ul>
                    <br>
                    Our approach ensures the perfect synergy between your project needs and our expertise,
                    guaranteeing the highest quality installation and commissioning of machinery.
                    </p>

                </div>
            </div>
            <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="50"
                class="informationItem rl">
                <picture>
                    <source srcset="../../assets/infoSupport.webp" type="image/webp">
                    <img src="../../assets/infoSupport.png" alt="ingeneers looking at a machine">
                </picture>
                <div>
                    <h4>Comprehensive Support <br>
                        <strong>for Every Stage</strong>
                    </h4>
                    <p>
                    <ul>
                        <li>From Build to Support:</li>
                    </ul>
                    Our automated installment systems in Chicago support extends beyond mere installation. We're here to
                    assist at
                    every project stage, from initial setup to optimization and maintenance, ensuring your robots in the
                    automotive industry exceed expectations.
                    </p>

                </div>
            </div>
            <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="50"
                class="informationItem">
                <picture>
                    <source srcset="../../assets/infoExpert.webp" type="image/webp">
                    <img src="../../assets/infoExpert.png" alt="Senior Engenieer working with a robot">
                </picture>
                <div>
                    <h4>Proven Expertise <br>
                        <strong>Across Brands and Systems</strong>
                    </h4>
                    <p>
                        With a team skilled in programming and setting up systems for all the major industrial robot
                        brands such as Kawasaki, Fanuc, ABB, Nachi, KUKA, and Yaskawa Motoman, DavisInd Group is
                        equipped to handle projects that demand precision and compatibility. This extensive capability
                        allows us to deliver customized and effective industrial automation solutions for any brand and
                        project size in the automotive industry and beyond.
                    </p>

                </div>
            </div>

        </section>
        <section id="contact" class="form">
            <div>
                <h2 data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="700">
                    Elevate your <strong>automation system installation <br> in Chicago</strong> with Davis Ind Group
                </h2>
                <p>Contact us to discover how we can bring unparalleled precision and expertise to your automation
                    needs.</p>
            </div>

            <hr>

            <form @submit.prevent="enviarMail2(name, phone, email, city, mensaje)">
                <input type="text" placeholder="Name" v-model="name">
                <input type="text" placeholder="Phone" v-model="phone">
                <input type="text" placeholder="Email" v-model="email">
                <input type="text" placeholder="City" v-model="city">
                <textarea placeholder="Tell us what you need." name="" id="" cols="30" rows="10"
                    v-model="mensaje"></textarea>
                <div class="send">
                    <div>
                        <div tabindex="0" @click.prevent="terms = !terms;" class="selectBox"
                            v-bind:class="{ selected: terms }">

                        </div>
                        <span>I have read and agreed to the <a href="/quality-policy">Privacy Policy</a></span>
                    </div>
                    <a>
                        <button type="submit">
                            <p>Send</p> <img src="../../assets/arrow3.svg" alt="Flecha de continuar">
                        </button>
                    </a>
                </div>
                <div class="advise-cont">
                    <div v-if="status == 'success'">
                        <p class="cart_msg cart_success">{{ msg }}</p>
                    </div>
                    <div v-if="status == 'error'">
                        <p class="cart_msg cart_error">{{ msg }}</p>
                    </div>
                </div>
            </form>
        </section>
        <Footer />
    </section>
</template>

<script>
import Footer from '../../components/Footer.vue'
import Navigator from '../../components/Navigator.vue'

export default {
    name: "maquinado-industrial",
    components: {
        Footer,
Navigator
    },
    data() {
        return {
            terms: false,
            status: "",
            msg: "",
            name: "",
            phone: "",
            email: "",
            city: "",
            mensaje: "",
        }
    },
    metaInfo: {
        title: 'Installation and commissioning in chicago', //Titulo de la pagina web
        meta: [
            {
                name: 'description',
                content: 'Our expertise in on-site robotic commissioning ensures the installation and setup of automated systems, specifically tailored to the demanding standards of the automotive industry.'

            }
        ]
    },
    methods: {
        enviarMail2: async function (name, phone, email, city, mensaje) {
            // Validate terms acceptance first
            if (!this.terms) {
                this.msg = "Accept the Privacy Policy to continue.";
                this.status = "error";
                return; // Exit the function early to prevent further processing
            }

            // Clear any previous messages or errors
            this.msg = "";
            this.status = "";

            // Existing field validations remain unchanged...
            if (name == undefined || name == "" || name == null) {
                this.msg = "Name field is not valid.";
                this.status = "error";
            } else if (phone == undefined || phone == "" || phone == null) {
                this.msg = "Phone field is not valid.";
                this.status = "error";
            } else if (email == undefined || email == "" || email == null) {
                this.msg = "Email is not valid.";
                this.status = "error";
            } else if (city == undefined || city == "" || city == null) {
                this.msg = "Service field is not valid.";
                this.status = "error";
            } else if (mensaje == undefined || mensaje == "" || mensaje == null) {
                this.msg = "Message field is not valid.";
                this.status = "error";
            } else {
                // Proceed with sending the email if all fields are valid and terms are accepted
                let data = {
                    name: name,
                    phone: phone,
                    email: email,
                    city: city,
                    mensaje: mensaje,
                };
                let response = await this.$store.dispatch(
                    "admin/enviarMailContacto2",
                    data
                );
                console.log(response)
                if (response.status == "success") {
                    this.status = "success";
                    this.msg = "Done! Your request has been processed.";
                    this.name = "";
                    this.phone = "";
                    this.email = "";
                    this.city = "";
                    this.mensaje = "";
                    this.delStatus();
                } else {
                    this.status = "error";
                    this.msg =
                        "Sorry. an error has occurred, Please try again later.";
                }
            }
        },
    }
}
</script>

<style scoped>
*,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.hero {
    width: 100%;
    height: 48.541666666666664VW;
    background-image: image-set(url("../../assets/landingHeroBg.webp") type("image/webp"),
            url("../../assets/landingHeroBg.png") type("image/png"));
    background-size: cover;
    padding: 4.947916666666666VW 0 9.947916666666666VW 10.9375VW;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: var(--MOSB_Light);
    position: relative;

}

.hero>div {
    display: flex;
    flex-direction: column;
    gap: 2.2395vw;
}

.hero a>img {

    width: 13.768229166666668VW;
    height: 4.780729166666667VW;
}

.hero h1 {
    font-size: 2.3958333333333335VW;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    color: #fff;
}

.hero h1 strong {
    font-weight: 200;
    color: #76E2FF !important;
}

.hero h3 {
    color: #fff;
    font-weight: 200;
    font-size: 1.5vw;
}

.description {
    width: 100%;
    display: flex;
    height: 39.84375VW;
    padding: 4.791666666666667VW 13.645833333333332VW 4.84375VW 15.78125VW;
    justify-content: flex-end;
    align-items: center;
    gap: 5.677083333333333VW;
}

.description img {
    width: 28.958333333333336VW;
    height: 30.208333333333332VW;
}

.description p {
    width: 35.9375VW;
    color: #474747;
    font-family: var(--OpenSans);
    font-size: 0.9375VW;
    font-style: normal;
    font-weight: 400;
    line-height: 152.682%;
}

.services {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 44.0625VW;
    background-image: image-set(url("../../assets/servicesBG.webp") type("image/webp"),
            url("../../assets/servicesBG.png") type("image/png"));
    background-size: cover;
    padding: 8.75VW 17.291666666666668VW;
    gap: 1.1458333333333333VW;
}

.services h3 {
    color: #76E2FF;

    text-align: center;
    font-family: var(--MOSB_Regular);
    font-size: 1.5625VW;
    font-style: normal;
    font-weight: 200;
    line-height: 152.682%;
}

.services p {
    color: #FFF;
    font-family: var(--OpenSans);
    font-size: 0.9375VW;
    font-style: normal;
    font-weight: 400;
    line-height: 152.682%;
    width: 43.28125VW;
    text-align: center;
}

.contService {
    display: flex;
    flex-direction: row;
    gap: 3.229166666666667VW;
}

.itemService {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.0416666666666665VW;
    width: 13.90625VW;
}

.itemService img {
    width: 5.989583333333334VW;
    height: 5.989583333333334VW;
}

.itemService p {
    color: #76E2FF;
    text-align: center;
    font-family: var(--MOSB_Regular);
    font-size: 0.9375VW;
    font-style: normal;
    font-weight: 200;
    line-height: 152.682%;
}

.itemService span {
    color: #FFF;
    text-align: center;
    font-family: var(--MOSB_Regular);
    font-size: 0.8125VW;
    font-style: normal;
    font-weight: 200;
    line-height: 152.682%;
}

.information {
    padding: 4.6875VW 6.406249999999999VW;
    gap: 1.8229166666666667VW;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.2395833333333335VW;
}

.information :nth-child(even) {
    flex-direction: row-reverse;
}

.informationItem {
    display: flex;
    flex-direction: row;
    gap: 3.802083333333333VW;
    align-items: center;
}

.informationItem img {
    width: 40.260416666666664VW;
    height: 28.541666666666664VW;
}

.informationItem>div {
    display: flex;
    flex-direction: column !important;
    gap: 1.875VW;
}

.informationItem h4 {
    color: #0096ed;

    font-family: var(--MOSB_Regular);
    font-size: 1.5625VW;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.informationItem h4 strong {
    color: #262FFD;
}

.informationItem p {
    width: 35.9375VW;
    color: #474747;

    font-family: var(--OpenSans);
    font-size: 0.8333333333333334VW;
    font-style: normal;
    font-weight: 400;
    line-height: 152.682%;
}

.informationItem ul {
    list-style: none;
    font-weight: 700;
}

.rl {
    text-align: right;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7.604166666666666VW 18.46875VW;
    gap: 3.4375000000000004VW;
    position: relative;
}

.form>div {
    display: flex;
    flex-direction: column;
    gap: 1.09375VW;
}

.form h2 {
    color: #262FFD;

    text-align: center;
    font-family: var(--MOSB_Regular);
    font-size: 2.44479166666666665VW;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}

.form h2 strong {
    color: #0096ed;
    font-weight: 200;
}

.form p {
    color: #474747;

    text-align: center;
    font-family: "Open Sans";
    font-size: 0.9375VW;
    font-style: normal;
    font-weight: 400;
    line-height: 152.682%;
}

.form hr {
    width: 2.604166666666667VW;
    height: 1px;
    border: 1px solid #0096ed;
}

form {
    width: 51.354166666666664VW;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625VW 0.7291666666666666VW;
}

form input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49.25%;
    border: 0.5px solid #679cff;
    padding: 1.1979166666666667VW 0;
    text-indent: 1.1979166666666667VW;
    font-family: var(--OpenSans);
    font-weight: 700;
}

form textarea {
    outline: 0.5px solid #679cff;
    border: none;
    padding: 1.1979166666666667VW 0 !important;
    text-indent: 1.1979166666666667VW;
    font-family: var(--OpenSans);
    width: 100%;
    height: 14.479166666666668VW;
    font-weight: 700;
    resize: vertical;
}

form textarea::placeholder,
form input::placeholder {
    color: #b7b7b7;
    font-weight: 200;

}

.send {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.083333333333333VW;
    color: #196BFE;

    font-family: var(--MOSB_Regular);
    font-size: 0.7291666666666666VW;
    font-style: normal;
    font-weight: 400;
    line-height: 174.187%;
    align-items: flex-start;
}

.send a {
    color: #0096ed;
    text-decoration: underline;
}

.send>div {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    align-items: center;
}

.selectBox {
    cursor: pointer;
    height: 0.7vw;
    width: 0.7vw;
    outline: 1px solid #0096ed;
    padding: 0.2vw;
    outline-offset: 0.25vw;
    border-radius: 0.1vw;

}

.selectBox.selected {
    background-color: #76E2FF !important;
}

a {
    text-decoration: none;
    width: fit-content;
}

button {
    display: flex;
    background: -webkit-linear-gradient(0deg, #0096ed 1.66%, #0096ed 26.68%, #262FFD 100.08%) 0% 0% / 300% 300%;
    padding: 1.7708333333333333VW 4.322916666666667VW;
    align-items: center;
    gap: 1.0416666666666665VW;
    border: none;
    cursor: pointer;
    transition: background 500ms ease;
}

button:hover {

    animation: gradient_move 2s ease-in-out;
    -webkit-animation: gradient_move 2s ease-in-out;
    animation-iteration-count: 4;

}


@-webkit-keyframes gradient_move {
    0% {
        background-position: 0% 92%
    }

    70% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}

@keyframes gradient_move {
    0% {
        background-position: 0% 92%
    }

    70% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}

button p {
    color: #FFF !important;

    text-align: center;
    font-family: var(--MOSB_Light);
    font-size: 1.1375VW;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

button img {
    width: 2.96875VW;
    height: 1vw;
}


.btn {
    position: absolute;
    background: linear-gradient(90deg, #262FFD -16.53%, #0096ed 95.45%);
    height: 10vw;
    width: 13VW;
    height: 10vw;
    top: -4vw;

    right: 17.5vw;
    border-radius: 0.5vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    clip-path: polygon(0 80%, 0 0, 100% 0, 100% 80%, 50% 100%);

    transition: all 300ms !important;
    animation: infinite call 4s;
}

@keyframes call {
    0% {
        transform: translateY(0vw);

    }

    45% {
        transform: translateY(0vw);
    }

    55% {
        transform: translateY(1vw);
    }

    65% {
        transform: translateY(0vw);

    }

    100% {
        transform: translateY(0vw);

    }
}

.btn p {
    text-align: center;
    margin: 0;
    color: white;
    font-family: var(--MOSB_Thin);
    transform: translateY(2vw);
    transition: all 300ms;
    font-size: 0.93vw;
}

.btn span {
    font-size: 1.2vw;

    font-family: var(--MOSB_Regular);

}

.decoAdd {
    height: 0;
    overflow: clip;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
    font-size: 1.3vw !important;

    transition: all 300ms;
    transform: translateY(0vw) !important;
    font-family: var(--MOSB_Regular) !important;


}

.decoAdd img {
    width: 3vw !important;
    height: 1vw !important;
}

.btn:hover {
    top: -1vw !important;
    animation: none;
    gap: 1vw;


}

.btn:hover p {
    transform: translateY(0vw);
}

.btn:hover .decoAdd {
    height: 2vw;
}

.advise-cont {

    width: 30vw;

    position: absolute;
    bottom: 2vw;
    left: 50%;
    margin-left: -15vw;
}

.cart_msg {
    color: rgb(255, 255, 255) !important;
    font-weight: 600 !important;
}

.cart_success {
    background-color: #0096ed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3vw;
    border-radius: 1vw;
}

.cart_error {
    background-color: rgb(255, 52, 52);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3vw;
    border-radius: 1vw;
}

@media(max-width: 769px) {
    .hero {
        height: 176.23529411764707VW;
        padding: 15.352941VW 9.88235294117647VW 19.058823529411764VW 13.882352941176471VW;
        background-image: image-set(url("../../assets/landingHeroBgMov.webp") type("image/webp"),
                url("../../assets/landingHeroBgMov.png") type("image/png"));
    }

    .hero>a img {
        width: 62.20000000000001VW;
        height: 21.59764705882353VW;
    }

    .hero h1 {
        font-size: 7.0588235294117645VW;
    }

    .hero h3 {
        font-size: 4.705882352941177VW;
    }

    .hero>div {
        gap: 7.529411764705881VW;

    }

    .hero a {
        margin: 0 auto;
    }

    button {
        gap: 4.705882352941177VW;
        padding: 8VW 9.647058823529411VW;
    }

    button img {
        width: 13.411764705882353VW;
    }

    button p {
        font-size: 4.235294117647059VW;
    }

    .btn {
        display: none;
    }

    .description {
        height: auto;
        flex-direction: column;
        gap: 8.705882352941176VW;
        padding: 20.47058823529412VW 6.11764705882353VW 26.588235294117645VW 7.294117647058823VW;


    }

    .description img {
        width: 80.23529411764706VW;
        height: 83.76470588235294VW;
    }

    .description p {
        width: 86.58823529411764VW;
        font-size: 4.841176470588235VW;
    }

    .services {
        height: auto;
        padding: 30.117647058823525VW 6.588235294117648VW 30.117647058823525VW 6.588235294117648VW;
    }

    .services h3 {
        color: #76E2FF;

        text-align: center;
        font-size: 7.0588235294117645VW;
        font-style: normal;
        font-weight: 700;
        line-height: 152.682%;
    }

    .services p {
        font-size: 4.941176470588235VW;
        width: auto;
    }

    .contService {
        flex-direction: column;
        gap: 7.529411764705881VW;
    }

    .contService img {
        height: 27.058823529411764VW;
        width: 27.058823529411764VW;
    }

    .itemService {
        width: 74.3529411764706VW;
    }

    .itemService span {
        font-size: 4.235294117647059VW;
    }

    .information {
        padding: 16.235294117647058VW 4.7VW 24.705882352941178VW 4.7VW;
        align-items: center;
        margin-bottom: 0;
        gap: 16.941176470588236VW;
    }

    .informationItem {
        flex-direction: column !important;
        gap: 7.0588235294117645VW;
    }

    .informationItem img {
        width: 86.58823529411764VW;
        height: 61.38470588235294VW;
    }

    .informationItem h4 {
        font-size: 7.0588235294117645VW;
    }

    .informationItem {
        text-align: left;
    }

    .informationItem>div {
        width: 100%;
    }

    .informationItem p {
        width: auto;
        font-size: 4.235294117647059VW;
    }

    .rl {
        text-align: right;
    }

    .form {
        padding: 27.764705882352942VW 8.235294117647058VW;
        gap: 16.235294117647058VW;
    }

    .form>div {
        gap: 8.705882352941176VW;
    }

    .form h2 {
        font-size: 6.5588235294117645VW;
    }

    .form p {
        font-size: 4.941176470588235VW;
    }

    .form hr {
        width: 11.76470588235294VW;
    }

    form {
        width: 83.76470588235294VW;
        gap: 3.294117647058824VW;
    }

    form input {
        width: 100%;
        height: 21.41176470588235VW;
        text-indent: 7.294117647058823VW;
        font-size: 4.235294117647059VW;
    }

    form textarea {
        padding: 7.29vw !important;
        font-size: 4.235294117647059VW !important;
        height: 40vw;
    }

    .send {
        flex-direction: column;
        gap: 12.23529411764706VW;
        font-size: 3.294117647058824VW;

    }

    .send>div {
        gap: 3.294117647058824VW;
    }

    .selectBox {
        width: 5.076470588235294VW;
        height: 5.076470588235294VW;
        margin-left: 1vw;
        outline-offset: 1vw;
    }

    .send a {
        width: 100%;
    }

    .send a button {
        justify-content: center;
        width: 100%;
    }

    .advise-cont {
        width: 80vw;
        margin-left: -40vw;
    }

    .cart_error {
        height: 20vw;
    }

    .cart_success {
        height: 20vw;
    }
}
</style>